import React from "react"
import { graphql } from "gatsby"
import Layout from "../../components/Layout"
import "../../styles/odooServices.scss"
import "../../styles/services.scss"

import News from "../../components/News"
import OdooTopSection from "../../components/OdooTopSection"
import WhyChooseVisiomateforD365 from "../../components/WhyChooseVisiomateforD365"
import RecentSuccessStories from "../../components/RecentSuccessStories"
import OdooSimplifiedStepstogetyouStarted from "../../components/OdooSimplifiedStepstogetyouStarted"
import Wethoughtyoumighthavequestions from "../../components/Wethoughtyoumighthavequestions"

import img1 from "../../images/Odoo/MMG-01.png"
import img2 from "../../images/Odoo/UnitedGulf-01.png"
const Index = ({ data }) => {
  return (
    <div className="Odoo">
      <Layout pageTitle={"Visiomate - Odoo Implementation Partner"}
      descrip = {"Tailor Odoo to your unique business needs. Our implementation partners provide customized solutions and ongoing support for long-term success."}>
        <OdooTopSection
          headerData={{
            title:
              "We excel in orchestrating business transformations through expert Odoo implementation and steadfast support services.",
          }}
          data={data}
        />
        <WhyChooseVisiomateforD365
          data={{
            title: "Why Choose Visiomate for Odoo?",
            headings: [
              {
                heading: "Crafting Tailored Solutions",
                description:
                  "At Visiomate, our seasoned professionals recognize the distinctiveness of every business. We pride ourselves on developing bespoke Odoo solutions meticulously crafted to harmonize seamlessly with your organization's goals and processes.",
              },
              {
                heading: "Seamless End-to-End Implementation",
                description:
                  "From the drawing board to flawless execution, our comprehensive Odoo implementation services pave the way for a seamless transition. Unlock the full potential of Odoo robust platform, empowering your business for success.",
              },
              {
                heading: "Proactive Support at Every Turn",
                description:
                  "Our commitment goes beyond implementation. We stand steadfast by your side with proactive support services. Our dedicated team ensures the continuous seamless operation of your Odoo environment, offering timely assistance, updates, and a commitment to ongoing improvements.",
              },
              {
                heading: "Navigating Industries with Expertise",
                description:
                  "With a diverse portfolio spanning the apparel and footwear sector, Visiomate brings a wealth of industry-specific knowledge to the table. Whether you're dealing with fashion trends, inventory management, or supply chain intricacies, our expertise is tailored to elevate your operations.",
              },
            ],
          }}
          dataFormImages={data}
        />
        <RecentSuccessStories
          data={{
            title: "Recent Success Stories ",
            Text: [
              {
                description:
                  "Muscat Media Group required a fully automated HR Management system mapped with their business needs, we provided them a solution using Odoo.",
                image: img1
              },
              {
                description:
                  "Visiomate team provided a solution for multi-step manufacturing process to United Gulf Pipe Manufacturing Co. LLC (UGPM)",
                image: img2
              },
            ],
          }}
          dataFormImages={data}
        />
        <OdooSimplifiedStepstogetyouStarted />
        <Wethoughtyoumighthavequestions
          data={[
            {
              question: "What is Odoo?",
              answer:
                "Odoo is an open-source suite of integrated business applications designed to help companies manage various aspects of their operations, including CRM, sales, inventory, manufacturing, accounting, HR, and more. It offers a modular approach, allowing organizations to select and customize the modules that best fit their needs.",
              answer1: "",
              answer2: "",
              answer3: "",
              answer4: "",
              answer5: "",
              answer6: "",
              answer7: "",
              answer8: "",
              answer9: "",
            },
            {
              question:
                "Why should I consider implementing Odoo for my business?",
              answer: "Implementing Odoo offers several benefits, including:",
              answer1:
                "- Comprehensive Suite: Access to a wide range of integrated business applications covering various functional areas, reducing the need for multiple disparate systems.",
              answer2:
                "- Scalability: Odoo is scalable and flexible, allowing organizations to start with a few modules and expand as their business grows or requirements change.",
              answer3:
                "- Cost-Effective: As an open-source solution, Odoo offers a lower total cost of ownership compared to proprietary software, with no licensing fees and affordable implementation and customization options.",
              answer4:
                "- Customization: Odoo can be customized to meet specific business requirements, with the ability to add or modify modules, workflows, reports, and user interfaces.",
              answer5:
                "- Community and Support: Access to a vibrant community of users, developers, and partners for knowledge sharing, collaboration, and support.",
              answer6: "",
              answer7: "",
              answer8: "",
              answer9: "",
            },
            {
              question: "What modules are included in Odoo?",
              answer:
                "Odoo offers a wide range of modules covering various functional areas, including:",
              answer1: "- CRM (Customer Relationship Management)",
              answer2: "- Sales",
              answer3: "- Inventory Management",
              answer4: "- Manufacturing",
              answer5: "- Accounting",
              answer6: "- Human Resources (HR)",
              answer7: "- Project Management",
              answer8: "- E-commerce",
              answer9: "- Point of Sale (POS) and many more",
            },
            {
              question: "What is involved in the implementation of Odoo?",
              answer:
                "The implementation of Odoo typically involves several key steps, including:",
              answer1:
                "- Requirement Analysis: Assessing business needs, processes, and requirements to determine the scope of the implementation.",
              answer2:
                "- Configuration: Configuring Odoo modules, workflows, and settings to align with business processes and requirements.",
              answer3:
                "- Data Migration: Importing existing data from legacy systems or spreadsheets into Odoo, ensuring data accuracy and completeness.",
              answer4:
                "- Customization: Developing customizations, enhancements, or integrations to extend Odoo functionality and meet specific business needs.",
              answer5:
                "- Training: Providing training and user adoption programs to educate employees on how to use Odoo effectively.",
              answer6:
                "- Testing: Conducting thorough testing and quality assurance to ensure that Odoo meets functional, performance, and usability requirements.",
              answer7:
                "- Deployment: Deploying Odoo in the production environment and providing ongoing support and maintenance as needed.",
              answer8: "",
              answer9: "",
            },
            {
              question: "How long does it take to implement Odoo?",
              answer:
                "The duration of an Odoo implementation can vary depending on factors such as the scope of the project, complexity of business processes, customization requirements, and organizational readiness. Typically, implementations can range from a few weeks to several months, with phased approaches to rollout and adoption.",
              answer1: "",
              answer2: "",
              answer3: "",
              answer4: "",
              answer5: "",
              answer6: "",
              answer7: "",
              answer8: "",
              answer9: "",
            },
            {
              question: "How can I ensure a successful Odoo implementation?",
              answer:
                "To ensure a successful Odoo implementation, consider the following best practices:",
              answer1:
                "- Define clear objectives, goals, and success criteria for the implementation project.",
              answer2:
                "- Engage stakeholders and obtain buy-in from key decision-makers across the organization.",
              answer3:
                "- Conduct thorough requirement analysis to understand business needs and processes.",
              answer4:
                "- Plan for adequate training, change management, and user adoption programs to support organizational readiness.",
              answer5:
                "- Work closely with experienced Odoo implementation partners or consultants with expertise in your industry and business processes.",
              answer6:
                "- Establish clear communication channels, project milestones, and accountability mechanisms to monitor progress and address issues promptly.",
              answer7: "",
              answer8: "",
              answer9: "",
            },
            {
              question: "Where can I get assistance with Odoo implementation?",
              answer:
                "Organizations can seek assistance with Odoo implementation from certified Odoo partners, consultants, and solution providers. These professionals offer expertise in Odoo implementation, customization, training, and support services tailored to the specific needs of each organization.",
              answer1: "",
              answer2: "",
              answer3: "",
              answer4: "",
              answer5: "",
              answer6: "",
              answer7: "",
              answer8: "",
              answer9: "",
            },
          ]}
          title="We thought you might have questions"
        />
        <News isAbout={true} career={true} />
      </Layout>
    </div>
  )
}

export default Index
export const query = graphql`
  query OdooImplementationImages {
    VMLogoBlack: file(relativePath: { eq: "logo_black_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    VMLogoWhite: file(relativePath: { eq: "logo_white_LST.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    Odooimg: file(relativePath: { eq: "Odoobg.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    markFlag: file(
      relativePath: { eq: "4879902_check_flag_mark_ok_icon_1_copy.png" }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }

    RSSImages1: file(relativePath: { eq: "Odoo/MMG-01.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }

    }  
    RSSImages2: file(relativePath: { eq: "Odoo/UnitedGulf-01.png" }) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
    SecondDivImg: file(
      relativePath: {
        eq: "two-male-colleagues-office-standing-with-laptop 1.png"
      }
    ) {
      childImageSharp {
        fluid {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`
