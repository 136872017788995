import React, { memo, useState } from 'react'

import InfoModal from "./InfoModal"
import { Button } from "antd"
import arrow from "../images/arrow.png"

const OdooSimplifiedStepstogetyouStarted = () => {
  const [isModalVisible, setIsModalVisible] = useState(false)

  const showModal = () => {
    setIsModalVisible(true)
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  return (
    <div>
      <div className="OdooSimplifiedStepstogetyouStarted">
        <h1>Simplified Steps to get you Started</h1>
        <div className="div-container">
          <div class="div1">
            <h2>01</h2>
            <p className="underlined-paragraph">
              <strong>User-Friendly Ticketing System</strong>
            </p>
            <ul>
              <li>
                Ensure the system captures essential details like severity,
                category, and a brief description.
              </li>
            </ul>
            <Button className="button-our" onClick={showModal}>
              Let’s Discuss
              <img src={arrow} width="20px" style={{ marginLeft: "4px" }} alt='Discuss arrow'/>
            </Button>

          </div>
          <div class="div2">
            <h2>02</h2>
            <p className="underlined-paragraph">
              <strong>Priority Assignment</strong>
            </p>
            <ul>
              <li>
                Assign priority levels to tickets based on their impact on
                business operations.{" "}
              </li>
              <li>
                Critical issues should be addressed with urgency, while routine
                queries can follow a standard timeline.
              </li>
            </ul>
          </div>
          <div class="div3">
            <h2>03</h2>
            <p className="underlined-paragraph">
              <strong>Clear Communication Channels</strong>
            </p>
            <ul>
              <li>
                Provide clear communication channels for users to interact with
                the support team.{" "}
              </li>
              <li>
                This can include a dedicated support portal, email, and a
                helpline, ensuring users can choose the most convenient option.
              </li>
            </ul>
          </div>
          <div class="div4">
            <h2>04</h2>
            <p className="underlined-paragraph">
              <strong>Root Cause Analysis</strong>
            </p>
            <ul>
              <li>
                Perform root cause analysis for recurring issues to address
                underlying problems and prevent future occurrences.{" "}
              </li>
              <li>
                This proactive approach contributes to long-term system
                stability.
              </li>
            </ul>
          </div>
          <div class="div5">
            <h2>05</h2>
            <p className="underlined-paragraph">
              <strong>Regular Status Updates</strong>
            </p>
            <ul>
              <li>
                Keep users informed about the status of their tickets with
                regular updates.{" "}
              </li>
              <li>
                Even if there's no resolution yet, proactive communication
                fosters trust and transparency.
              </li>
            </ul>
          </div>
          <div class="div6">
            <h2>06</h2>
            <p className="underlined-paragraph">
              <strong>User Feedback Loop</strong>
            </p>
            <ul>
              <li>
                Establish a feedback loop to gather input from users about their
                support experiences.{" "}
              </li>
              <li>
                Use this feedback to identify areas for improvement and refine
                the support process.
              </li>
            </ul>
          </div>
          <div class="div7">
            <h2>07</h2>
            <p className="underlined-paragraph">
              <strong>Post-Resolution Follow-up</strong>
            </p>
            <ul>
              <li>
                Conduct post-resolution follow-ups with users to ensure their
                issues are fully resolved and gather insights for further
                improvements.{" "}
              </li>
              <li> This step enhances user satisfaction and loyalty.</li>
            </ul>
          </div>
          <div class="div8">
            <h2>08</h2>
            <p className="underlined-paragraph">
              <strong>Continuous Training for Support Team</strong>
            </p>
            <ul>
              <li>
                Ensure the support team undergoes continuous training to stay
                updated on the latest Dynamics 365 features, updates, and best
                practices.{" "}
              </li>
              <li>This empowers them to provide expert assistance.</li>
            </ul>
          </div>
        </div>
      </div>
      <InfoModal
        companyIsEnabled
        url="http://185.213.27.237:8066/Email/ContactUs"
        isModalVisible={isModalVisible}
        handleOk={handleOk}
        handleCancel={handleCancel}
      />
    </div>
  )
}

export default memo(OdooSimplifiedStepstogetyouStarted)
